import { GetCurrentWorkResponse, Project, SerializedWork, Work } from "./types";
import { Button, Card } from "antd";
import { db, toggleCurrentWork } from "./firebase";
import { getDoc, doc } from "firebase/firestore";

type Props = {
  project: Project;
  currentWork: Work | null;
  setCurrentWork: (work: Work | null) => void;
};

export default function ProjectPage({
  project,
  setCurrentWork,
  currentWork,
}: Props) {
  let buttonLabel = "clock out";
  let buttonType: "primary" | "default" = "primary";
  if (currentWork?.project?.id !== project.projectRef.id) {
    buttonLabel = "clock in";
    buttonType = "default";
  }

  const toggleCurrentEvent = async () => {
    const response = await toggleCurrentWork({
      toggleProject: project.projectRef.path,
    });
    // const response = await getCurrentProject();
    const responseData = response.data as GetCurrentWorkResponse;
    if (responseData.success == true) {
      if (responseData.work != null)  {
        const project = doc(db, responseData.work?.projectPath);
        setCurrentWork({
          start: responseData.work.start,
          end: responseData.work.end,
          project: project,
        });
      } else {
        setCurrentWork(null);
      }
    }
  };

  return (
    <Card
      title={project.name}
      extra={
        <Button type={buttonType} onClick={toggleCurrentEvent}>
          {buttonLabel}
        </Button>
      }
    >
      {project.description}
    </Card>
  );
}
