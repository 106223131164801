import { collection, setDoc, getDoc, doc, Timestamp } from "firebase/firestore";

import { db } from "./firebase";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  UserProfile,
} from "firebase/auth";

const auth = getAuth();
const provider = new GoogleAuthProvider();
const signInWithGoogle = async (): Promise<void> => {
  signInWithPopup(auth, provider)
    .then(async (result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      if (credential) {
        const user = result.user;
        const docRef = doc(collection(db, "users"), user.uid);
        const userRef = await getDoc(docRef);
        if (!userRef.exists()) {
          const userProfile: UserProfile = {
            displayName: user.displayName,
            email: user.email,
            photoURL: user.photoURL,
            createdAt: Timestamp.fromDate(new Date()),
            projects: [],
            current: null,
          };
          await setDoc(docRef, userProfile);
        }
      }
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      const credential = GoogleAuthProvider.credentialFromError(error);
      console.error(errorCode, errorMessage, credential);
    });
};

const signOut = async (): Promise<void> => {
  try {
    await auth.signOut();
  } catch (error) {
    console.error("Error signing out:", error);
  }
};

export { signInWithGoogle, signOut };
