// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAWu249sXIan0clCkQBFc7UFZS7w5MSufM",
  authDomain: "hours-of-work.firebaseapp.com",
  projectId: "hours-of-work",
  storageBucket: "hours-of-work.appspot.com",
  messagingSenderId: "342706782527",
  appId: "1:342706782527:web:d2c3a5cfac12f2dad3138a",
  measurementId: "G-353BGWG2FJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const toggleCurrentWork = httpsCallable(functions, 'toggleCurrentWork');
export { auth, db, toggleCurrentWork };
