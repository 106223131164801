import React, { useEffect, useState } from "react";
import { db } from "./firebase";
import { auth } from "./firebase";
import { Project, UserProfile, Work } from "./types";
import { User } from "firebase/auth";
import { signOut } from "./auth";
import { signInWithGoogle } from "./auth";
import { Button, Col, Divider, Empty, Flex, Row } from "antd";
import ProjectPage from "./ProjectPage";
import { StarFilled } from "@ant-design/icons";

import "./App.css";
import { collection, doc, DocumentReference, getDoc } from "firebase/firestore";

function App() {
  const [user, setUser] = useState<User | null>(null);
  const [projects, setProjects] = useState<Array<Project>>([]);
  const [openProject, setOpenProject] = useState<Project | null>(null);
  const [currentWork, setCurrentWork] = useState<Work | null>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user: User | null) => {
      setUser(user);
      if (user != null) {
        const userRef = await getDoc(doc(collection(db, "users"), user.uid));
        const userProfile = userRef.data() as UserProfile;

        const projectRefs =
          (userProfile?.projects as Array<DocumentReference>) ?? [];
        const userProjects = await Promise.all(
          projectRefs.map(async (projRef: DocumentReference) => {
            const projSnap = await getDoc(projRef);
            let projData = projSnap.data() as any;
            projData.projectRef = projRef;
            return projData as Project;
          })
        );

        const currentWorkRef = userProfile.currentWork;
        if (currentWorkRef != null) {
          const currentWorkSnap = await getDoc(currentWorkRef);
          setCurrentWork(currentWorkSnap.data() as Work);
        } else {
          setCurrentWork(null);
        }

        setProjects(userProjects);
      } else {
        setProjects([]);
        setOpenProject(null);
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <div>
      <div className="App">
        <Row>
          <Col span={12} offset={6}>
            <Flex gap="middle" align="center" justify="space-between">
              <h1>100 Hours of Work</h1>
              {user ? (
                <Button onClick={signOut}>Sign Out {user.email}</Button>
              ) : (
                <Button onClick={signInWithGoogle}>Sign in with Google</Button>
              )}
            </Flex>
            It takes 10,000 hours to become an expert in a field. Those 10,000
            hours are built on a hundred 100-hour blocks. 100 Hours of Work is a
            website which helps you track your progress in these more manageable
            100-hour blocks.
          </Col>
        </Row>
        <Row>
          <Col span={12} offset={6}>
            <Flex wrap gap="small">
              {projects.map((project) => {
                const buttonType =
                  project == openProject ? "primary" : "default";
                return (
                  <Button
                    key={project.projectRef.id}
                    type={buttonType}
                    iconPosition="start"
                    icon={
                      currentWork?.project?.id == project.projectRef.id && (
                        <StarFilled />
                      )
                    }
                    onClick={() => {
                      setOpenProject(project == openProject ? null : project);
                    }}
                  >
                    {project.name}
                  </Button>
                );
              })}
            </Flex>
          </Col>
        </Row>

        <Row>
          <Col span={12} offset={6}>
            {openProject != null && (
              <ProjectPage
                project={openProject}
                currentWork={currentWork}
                setCurrentWork={setCurrentWork}
              />
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default App;
